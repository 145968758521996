import { BASE_ENDPOINT } from '@apps/config';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import md5 from 'md5';

type AnalyticType =
  | 'ads_block_placement_view'
  | 'ads_block_placement_impression'
  | 'setting_banner_total_view'
  | 'setting_banner_total_impression';

type Props = {
  id: string;
  type: AnalyticType;
  platform?: string;
};

export const graphAnalytics = async (props: Props) => {
  const { id, platform, type } = props;

  try {
    const baseUrl = `${BASE_ENDPOINT}`;
    const encryptionCode = process.env.NEXT_PUBLIC_ENCRYPTION_CODE;

    if (baseUrl && encryptionCode) {
      const encrypt_code = md5(id + encryptionCode);
      let url = `${baseUrl}/graph_analytics/${type}`;

      // Create an object to hold the query parameters
      const params = new URLSearchParams();

      const setParams = (key?: string, value?: string) => {
        if (isEmpty(value) || isEmpty(key) || !key || !value) return;
        params.append(key, value);
      };

      setParams('id', id?.toString());
      setParams('platform', platform || 'carz');
      setParams('encrypt', encrypt_code);

      // If there are any parameters, append them to the base URL
      if (params.toString()) {
        url += `?${params.toString()}`;
      }
      await axios.get(url);
    }
  } catch (e) {
    console.log(`[Error] Ads Graph Analytic (${type}): `, e);
  }
};
